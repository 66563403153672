.scoring-entities {
  background-color: #fff;
  margin: auto;
  border-collapse: collapse;

  tr {
    height: 30px;
    cursor: pointer;
    border-bottom: 1px solid $orderly-toolkit-black2;

    .suggestion-description {
      width: 650px;
      text-align: start;
    }

    th {
      border-bottom: 1px solid $orderly-toolkit-black2;
      font-size: 18px;
      padding: 15px 60px;
    }

    td {
      padding: 15px 30px;
    }
  }

  .action-img {
    width: 20px;
  }

  img {
    width: 15px;
  }

  .actions {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 55px;
    }

    .action-icon {
      padding-left: 15px;
    }
  }

  thead {
    tr {
      background-color: #f0f0f0;
    }
  }
}

h3 {
  color: #fff;
}

.back-btn {
  position: absolute;
  cursor: pointer;
  left: 100px;
  padding: 10px;
  background-color: #fff;
  border: 0;
  margin-left: 10px;
}

.table-wrapper {
  overflow-x: auto;
}

.table-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-wrapper::-webkit-scrollbar {
  width: 2px !important;
  height: 10px;
  background-color: #f5f5f5;
}

.table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(209, 206, 206);
}

.usefulText {
  font-weight: bold;
}

.dashboard-filters {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

.dashboard-filters-btn {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}