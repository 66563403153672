.seasonal-content-wrapper {
  display: none;
  flex-direction: row;
  width: 60%;
  margin: auto;
  margin-bottom: 20px;

  &.show {
    display: flex;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  img {
    max-width: 55%;
    object-fit: cover;
    cursor: pointer;
  }

  .seasonal-content {
    background-color: #fff;
    color: #000;
    width: 45%;

    .seasonal-content-title {
      font-size: 40px;
      font-weight: bold;
      color: #6f2a3b;
      margin: 40px;
      text-align: start;

      @media screen and (max-width: 750px) {
        text-align: center;
        margin: 25px;
        font-size: 25px;
      }
    }

    .seasonal-content-description {
      width: 80%;
      margin: 20px 40px;
      text-align: start;

      @media screen and (max-width: 750px) {
        margin: 20px auto;
      }
    }
  }
}