@import './../../Shared/Variables/Variables.scss';

.swipeable-list {
  max-width: 575px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #666;
}

.suggestion {
  width: 100%;
  max-width: 575px;
  background-color: $orderly-toolkit-white;
  border-radius: 5px;
  min-height: 400px;
  max-height: 600px;
  padding: 30px 0;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #666;
  position: relative;
  transform: translateX(0);

  &.unswiped {
    transform: translateX(575px);
  }

  &.dim {
    opacity: 0.8;
    background-color: gainsboro;
  }

  &.inverted {
    background-color: #6f2a3b;
    box-shadow: 0 0 10px 0 #6f2a3b;

    p {
      color: #fff;
    }

    h3 {
      color: #fff;
    }
  }

  .suggestion-header {
    padding: 5px 0 0 10px;
    display: flex;
    align-items: center;
    color: #000;

    p {
      margin-left: 10px;
      font-family: 'tthoves-medium';
      font-size: 13px;
    }

    .clock-icon {
      background-image: url(../../../../public/assets/clock-icon.png);
      width: 30px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .title {
    color: #6f2a3b;
    font-size: 40px;
    font-family: 'tthoves-bold';
    margin-top: 40px;
    padding: 0 20px;

    @media only screen and (max-width: 500px) {
      font-size: 30px;
    }
  }

  .helping-text-subSuggestions {
    color: gray;
  }

  .desc {
    font-size: 30px;
    padding: 0 20px;

    @media only screen and (max-width: 500px) {
      font-size: 20px;
    }
  }

  .suggestion-icon {
    width: 97%;
    display: flex;
    justify-content: flex-end;

    img {
      width: 25px;
    }
  }
}
