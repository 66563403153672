.content-banner {
  background-color: $orderly-toolkit-white;
  width: 60%;
  min-height: 75px;
  margin: 15px auto;
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  .bg-black {
    background-color:#f4f6f8;
    color: $orderly-toolkit-white;
    font-size: 25px;
    font-weight: 500;
    width: 120px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
       
  img {
    width: 50%;
  }
    .content {
      width: 100%;
      padding: 35px 15px;
      font-family: "tthoves-medium";
      background-color: $orderly-toolkit-white;
      color: #000;
      display: flex;
      align-items: center;
      text-align: start;
  
    }

}
