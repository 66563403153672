.circular-bar {
  width: 100px;
  display: inline-block;
  color: white;
  margin: 15px;
}

.circular-bar-title {
  font-size: 12px;
}

.circular-bar-value {
  font-size: 20px;
}
