.title {
  color: #fff;
}

.stacked-cards {
  position: relative;
  width: 545px;
  height: 70px;
  margin: auto;
  margin-top: -50px;
  z-index: -1;

  .card {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    z-index: 3;

    &.card-2 {
      transform: translateY(25%) scale(0.9);
      z-index: 2;
    }

    &.card-3 {
      transform: translateY(50%) scale(0.85);
      z-index: 1;
    }
  }
}
