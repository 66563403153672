.training-item-management-wrapper {
  .training-item-management-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: auto;

    @media only screen and (max-width: 950px) {
      width: 100%;
    }

    h1 {
      color: #fff;
      text-align: center;
    }

    .training-item-management-content {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;

      .training-item-management-column {
        display: flex;
        flex-direction: column;

        .training-item-management-input {
          margin: 20px 0;

          input {
            padding-left: 5px;
            width: 95%;
            height: 35px;
          }

          label {
            color: #fff;
            font-weight: 400;
            margin-bottom: 10px;
          }

          select {
            padding: 5px;
            width: 100%;
            height: 40px;
          }
        }
      }

      .training-item-management-image {

        #thumbnail,
        #content {
          display: none;
        }

        label {
          .image-label {
            color: #fff;
            margin-bottom: 10px;
            text-align: center;
          }

          img {
            width: 170px;
            height: 170px;
            background: #fff;
            border: 1px solid #000;
            border-radius: 20px;
            cursor: pointer;

            &.sm {
              padding: 70px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .training-item-management-footer {
      display: flex;
      justify-content: center;
      margin-top: 50px;

      button {
        width: 120px;
        height: 40px;
        margin: 0 10px;
        cursor: pointer;
        border: 0;
      }

      button:disabled {
        opacity: 0.8;
        background-color: #fff;
        border: 0;
      }

      input[type=submit] {
        background-color: #fff;
        font-family: $orderly-font-medium;
        width: 120px;
        height: 40px;
        margin: 0 10px;
        cursor: pointer;
        border: 0;
      }

      input[type=submit]:disabled {
        opacity: 0.8;
      }
    }
  }
}