.pagination-wrapper {
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .pagination-page {
      margin: 0 5px;
      padding: 10px 20px;
      border: 1px solid #c3b9b5;
      background-color: #6f2a3b;
      color: #fff;
      cursor: pointer;
      user-select: none;

      &.disabled {
        opacity: 0.5;
        cursor: unset;
      }

      &.disabled:hover {
        background-color: #6f2a3b;
      }
    }

    .dummy-page {
      color: #fff;
    }

    .pagination-page.active {
      background-color: #3e0f1b;
      color: #fff;
    }

    .pagination-page:hover {
      background-color: #3e0f1b;
    }

    .pagination-page:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .pagination-page:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}