.list-metric-container {
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  font-weight: bolder;
  text-align: left;
  padding: 10px 25px;

  .list-metric-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      margin: 30px auto;
      font-size: 21px;
      font-family: 'tthoves-bold';
      color: #7a7979;
    }
  }
}
