.modal-wrapper {
  .modal-blocker.ui-toolkit {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .modal-content {
      background: none !important;
      padding: 15px;
      padding-bottom: 40px;
      padding-left: 80px;
      padding-right: 80px;
      max-width: 40%;

      form {
        background-color: #fff;
        margin-top: 36px;
        padding: 15px;
        padding-bottom: 40px;
        padding-left: 80px;
        padding-right: 80px;
        max-width: 95%;
        border-radius: 9px;

        .modal-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h2 {
            font-size: 24px;
            font-family: $orderly-font-bold;
            text-align: center;
          }
        }

        .modal-body {
          margin-top: 20px;
          margin-bottom: 25px;
          font-size: 20px;
        }

        .modal-buttons {
          display: flex;
          align-items: center;
          padding-top: 15px;
          justify-content: space-around;

          button {
            width: 150px;
            border: none;
            height: 50px;
            font-size: 16px;
            margin: 14px;
            margin-bottom: 20px;
            font-weight: normal;
            background-color: $orderly-toolkit-black2;
            color: $orderly-toolkit-white;
            cursor: pointer;
          }

          .negative {
            background-color: rgba(0, 0, 0, 0.2);
            color: black;
          }
        }
      }
    }
  }
}