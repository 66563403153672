.morning-brew-container {
  width: 85%;
  height: 100%;
  margin: 0 auto;
  padding-top: 15px;

  .morning-brew-subheading {
    font-size: 35px;
  }

  .morning-brew-header {
    color: #fff;
  }

  .percentage-card {
    background: #fff;
    width: 60%;
    margin: 0 auto;
    height: 200px;
    font-weight: bold;
    font-size: 20px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .title {
      font-size: 25px;
      margin-top: 0;
      margin-bottom: 15px;
      color: #7a7979;
    }

    .content {
      position: relative;

      .current-expense {
        position: absolute;
        top: -15px;
        color: #fff;
        text-shadow: 0 0 4px #000;

        @media screen and (max-width: 850px) {
          left: -25px;
        }

        @media screen and (max-width: 500px) {
          left: -25px;
        }
      }

      .max-expense {
        width: 135%;
        color: green;
      }

      .percentage-wrapper {
        width: 90%;
        margin: 0 auto;
        height: 35px;
        background: #ebeaea;

        .percentage {
          background: #6f2a3b;
          height: 100%;
          font-weight: bolder;
        }

        .expectedTarget {
          position: absolute;
          top: 0;
          left: 68%;
          width: 2px;
          height: 35px;
          background: green;
        }
      }
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .cards {
    width: 60%;
    margin: 0 auto;
    height: fit-content;
    display: grid;
    grid-template-columns: 2fr 2fr;
    padding: 10px 0;
    gap: 10px;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }

  .chart {
    width: 60%;
    margin: 0 auto;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;

    a {
      text-decoration: none;
      background: #6f2a3b;
      color: #fff;
      padding: 10px 18px;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
