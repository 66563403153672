.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  text-align: center;

  img {
    position: relative;
    top: 50%;
  }
}

.loading-wrapper {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 150px;
    position: relative;
    top: 50%;
  }

  &.centered {
    img {
      margin-top: 250px;
      width: 150px;
      position: relative;
      top: 30%;
    }
  }
}
