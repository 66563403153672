.training-management-wrapper {
  h1 {
    text-align: center;
    color: #fff;
  }

  .training-management-container {
    width: 90%;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    @media only screen and (min-width: 1500px) {
      width: 70%;
    }

    .training-management-header {
      padding: 20px 0;

      .training-management-header-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .training-management-back-btn {
          position: absolute;
          left: 0;
          top: 40px;
          padding: 10px 40px;
          border: 0;
          cursor: pointer;
        }

        .training-management-add-btn {
          padding: 10px 40px;
          color: #fff;
          background-color: #6f2a3b;
          border: 0;
          cursor: pointer;
          margin-left: auto;
        }

        input {
          padding: 10px;
        }
      }

      .training-management-header-row:last-child {
        justify-content: space-between;
      }
    }

    h3 {
      text-align: center;
    }

    .training-items {
      background-color: #fff;
      margin: auto;
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          background-color: #f0f0f0;
        }
      }

      tr {
        height: 30px;
        cursor: pointer;
        border-bottom: 1px solid $orderly-toolkit-black2;

        .suggestion-description {
          width: 650px;
          text-align: start;
        }

        th {
          border-bottom: 1px solid $orderly-toolkit-black2;
          font-size: 18px;
          height: 50px;
          text-align: start;
        }

        th:first-child {
          padding-left: 20px;
        }

        th:last-child {
          width: 200px;
          text-align: center;
        }

        td:first-child {
          padding-left: 20px;
        }
      }

      .actions {
        padding: 0;
        display: flex;
        height: 40px;
        justify-content: space-evenly;

        span {
          display: inline-flex;

          img {
            width: 20px;
            object-fit: contain;
          }
        }

        .action-icon {
          padding-left: 15px;
        }
      }
    }
  }
}