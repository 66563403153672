.tooltip-wrapper {
  display: flex;
  text-align: left;

  .tooltip-toggle {
    background-color: #f2cd00;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }

  .tooltip-show {
    display: flex;
    max-width: 200px;
    margin: 4px;
    padding: 12px;
    font-family: "tthoves-regular";
    font-size: 14px;
    color: #2e2d2c;
    position: absolute;
    background: transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  }

  .tooltip-hide {
    display: none;
  }
}
