.training-container {
    text-align: center;
    color: white;
    width: 80%;
    margin: auto;


}

.training-card-container,
.training-card-container-catalog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
}


.training-card-img,
.training-card-img-catalog {
    border-radius: 20px;
    border: 13px solid #6f2a3b;
    border-bottom: 38px solid #6f2a3b;
    cursor: pointer;
}

.training-card-img {
    width: 180px;
    height: 180px;

    @media screen and (max-width: 800px) {
        width: 150px;
        height: 150px;
    }
}

.training-card-img-catalog {
    width: 250px;
    height: 250px;

    @media screen and (max-width: 800px) {
        width: 240px;
        height: 240px;
    }
}

.training-card-tile {
    position: absolute;
    margin-top: -34px;
    margin-left: 15px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16%;

    @media screen and (max-width: 800px) {
        max-width: 27%;
    }

    @media screen and (min-width: 1500px) {
        max-width: 12%;
    }
}

.training-card-link-margin {
    margin-right: 20px;
    margin-bottom: 20px;
}

.training-card-margin {
    margin-right: 30px;
    margin-bottom: 30px;
}

.get-started-heading {
    margin-top: 50px;
    font-size: 18px;
}