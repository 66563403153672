.seasonal-recipe-content-wrapper {
    display: none;
    flex-direction: row;
    width: 60%;
    margin: auto;

    &.show {
        display: flex;
    }

    @media screen and (max-width: 1280px) {
        width: 100%;
    }

    img {
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    .seasonal-content {
        background-color: #fff;
        color: #000;
        width: 45%;

        .seasonal-content-title {
            font-size: 40px;
            font-weight: bold;
            color: #6f2a3b;
            margin: 40px;
            text-align: start;

            @media screen and (max-width: 750px) {
                text-align: center;
                margin: 25px;
                font-size: 25px;
            }
        }

        .seasonal-content-description {
            width: 80%;
            margin: 20px 40px;
            text-align: start;

            @media screen and (max-width: 750px) {
                margin: 20px auto;
            }
        }
    }

.items-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}

.item-wrapper {
    width: 48%;
    margin-bottom: 10px;
    position: relative;
}

.item-wrapper img {
        width: 100%;
        height: 95%;
}
  
.recipe-title-wrapper {
    position: absolute;
    top: 6%;
    left: 0;
    width: 100%;
}

.recipe-title {
    margin: 0;
    color: #000;
    font-size: 1.2rem
}

.recipe-button-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: 78%;
}

.recipe-button {
    width: 35%;
    margin-top: 10px;
    color: #ffffff;
    background-color: #000000;
    border: 4px solid #000000;
    cursor: pointer;
    font-size:85%;
    @media screen and (max-width: 1280px) {
        font-size: 75%;
        }
}
}