@import './../../Shared/Variables/Variables.scss';

.header-bar {
  background-color: $orderly-toolkit-black2;
  min-height: 40px;
  font-size: calc(10px + 2vmin);
  color: white;

  .inner-container {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 30px;
  }

  .right-section {
    display: flex;
    align-items: center;

    button {
      margin: 0 20px;
      color: #fff;
      background-color: $orderly-toolkit-black2;
      border: 0;
      cursor: pointer;
    }

    p {
      margin: 0 20px;
      vertical-align: middle;
    }
  }

  .header-text {
    font-size: 15px;

    span {
      color: $orderly-toolkit-yellow;
      cursor: pointer;

      &:hover {
        color: $orderly-toolkit-grey;
      }
    }
  }
}
