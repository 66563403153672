
.wave-score.ui-toolkit {
  margin: 7px;

  .score-values {
         display: flex;
    margin-top: -10px;
    justify-content: center;
    margin-bottom: 12px;


    .current-score {
      font-family: $orderly-font-bold;
         font-size: 60px;
    line-height: 24px;
    }

    .total-score {
      font-family: $orderly-font-bold;
      font-size: 28px;
    }
  }

  svg {
align-self: flex-start;
    height: 110px;

    image.score-image {
      width: 50px;
    }
  }
}
