.login-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  background-color: $orderly-toolkit-white;
  border-radius: 5px;
  padding: 30px;
  margin: 0 auto;
  margin-top: 90px;
  padding-bottom: 50px;
  box-shadow: 0 0 10px 0 #666;
  position: relative;

  @media only screen and (max-width: 400px) {
    margin-top: 0;
  }

  button {
    margin: 25px 0;
    border-radius: 50px;
    background-color: #6f2a3b;
    color: #fff;
    border: 0;
    font-family: 'tthoves-demibold';
    cursor: pointer;
    padding: 20px;
  }

  h2 {
    margin: 25px 0;
    font-size: 35px;

    @media only screen and (max-width: 400px) {
      margin: 20px 0;
      font-size: 30px;
    }
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 25px;

    @media only screen and (max-width: 400px) {
      margin-bottom: 20px 0;
      font-size: 20px;
    }
  }
}
