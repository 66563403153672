.weather-widget {
  background-color: #fff;

  .widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header-left {
      .current-temp {
        display: flex;
        align-items: center;

        .temp-value {
          font-size: 40px;
          display: flex;

          span {
            font-size: 20px;
            color: #70757a;
          }
        }
      }

      .weather-modes {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .weather-mode {
          margin: 10px 20px;
          white-space: nowrap;
          padding-bottom: 4px;
          cursor: pointer;

          @media only screen and (max-width: 900px) {
            margin: 10px 5px;
          }

          &.active {
            border-bottom: 3px solid #fbbc04;
          }
        }
      }
    }

    .header-right {
      text-align: end;
      margin-right: 10px;
      margin-top: 10px;

      .location {
        max-width: 350px;
        font-size: 24px;
      }

      .weekday,
      .weather-description {
        color: #70757a;
        margin-top: 5px;
      }
    }
  }
}
