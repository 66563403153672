.targets-wrapper{
    width: 575px;
    margin: auto;
    margin-top: 100px;
    overflow: hidden;
    border-radius: 5px;
}

.targets-bar{
  background-color: #3E0F1B;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.targets-button:hover{
  background-color: #2b0a12;
}

.targets-icon {
  float: left;
}

.targets-arrow {
  float: right;
}

.targets-collapsible-content {
  padding: 0 18px;
  overflow: hidden;
  background-color: #6F2A3B;
}

.collapsed {
    display: none;
}

.targets-header{
    display: flex;
    justify-content: space-between;
}