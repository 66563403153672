.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 1;
}

.img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1;

  @media only screen and (max-height: 800px) {
    top: 10px;
  }

  img {
    width: 90vw;
    height: 90vh;
    object-fit: contain;
  }

  .slider {
    display: none;

    @media only screen and (max-width: 1200px) {
      display: block;
    }
  }

  .desktop-slider {
    display: block;

    @media only screen and (max-width: 1200px) {
      display: none;
    }

    .left-chevron {
      position: fixed;
      left: 0;
      top: 50%;
      height: 50px;
      width: 50px;
      cursor: pointer;
    }

    .right-chevron {
      position: fixed;
      right: 0;
      top: 50%;
      height: 50px;
      width: 50px;
      cursor: pointer;
    }
  }
}

.pagination {
  position: fixed;
  bottom: 50px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 20px;
  z-index: 1;

  span {
    width: 100px;
    height: 40px;
    background: #6f2a3b;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px #000;
    border-radius: 5px;
  }
}
