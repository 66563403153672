.daily-forecast-container {
  width: 85%;
  height: 100%;
  margin: 0 auto;
  padding-top: 15px;

  .daily-forecast-header {
    color: #fff;
  }

  .chart {
    width: 60%;
    margin: 0 auto;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }

  .list-metrics-wrapper {
    width: 60%;
    margin: auto;
    margin-bottom: 20px;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .list-metric-content {
      font-size: 16px;
      width: 100%;
      font-family: 'tthoves-regular';
      font-weight: 100;
      margin: auto;
      text-align: center;
      max-height: 450px;
      overflow-y: auto;

      @media only screen and (max-width: 1280px) {
        max-height: 300px;
      }

      .list-metric-item {
        display: flex;
        padding: 6px 20px;

        @media only screen and (max-width: 1280px) {
          padding: 6px 16px;
        }

        @media only screen and (max-width: 900px) {
          padding: 6px 8px;
        }

        .item-number {
          width: 25%;
          text-align: left;
          color: #6f2a3b;
          font-weight: bold;

          @media only screen and (max-width: 900px) {
            width: 80px;
          }
        }

        .item-description {
          width: 55%;
          text-align: left;
          margin: 0 30px;
          max-width: unset;
        }

        .item-qty {
          text-align: left;
          margin-left: 5px;
          width: 25%;
        }

        .item-running-out {
          text-align-last: right;
          max-width: unset;
          white-space: nowrap;
        }
      }

      .break-line {
        height: 0.5px;
        width: 95%;
        background-color: #7a7979;
        margin: auto;
      }
    }
  }

  .weather-widget {
    width: 60%;
    margin: auto;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }

  .footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;

    a {
      text-decoration: none;
      background: #6f2a3b;
      color: #fff;
      padding: 10px 18px;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
