@import '../../Variables/Variables.scss';

.scorecard-card-wrapper {
  min-height: 520px;
  border-radius: 10px;
  width: 450px;
  background-color: $orderly-toolkit-white;
  box-shadow: 0 0 10px 0 #cbcbcb;
  margin: 0 auto;
  position: relative;
  height: 650px;

  .tootipAndWaveScore {
    display: flex;
    flex-direction: column-reverse;
  }

  .learn-more {
    border: none;
    outline: none;
    background: $orderly-toolkit-yellow;
    padding: 10px;
    width: 230px;
    height: 50px;
    left: 110px;
    position: absolute;
    bottom: -25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 59px;
    cursor: pointer;

    &:hover {
      background: $orderly-toolkit-grey;
    }

    .right-chevron {
      background-image: url(../../../../../public/assets/right-chevron.png);
      width: 30px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 16px;
    }
  }

  .toolTip-container,
  .box {
    display: flex;
    margin: 15px 5px;
    flex-direction: row-reverse;

    .tooltip-show {
      width: fit-content;

      @media screen and (max-width: 940px) {
        width: 25%;
      }

      @media screen and (max-width: 800px) {
        top: 50px;
        right: -45px;
        z-index: 5;
      }
    }
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 50px;

    @media only screen and (max-width: 1000px) {
      padding-bottom: 40px;
    }

    @media only screen and (min-width: 1000px) {
      margin-bottom: 10px;
    }
  }

  .suggestion-item {
    flex-grow: 1;
    width: 90%;
    padding: 10px;

    svg {
      @media only screen and (min-width: 1000px) {
        width: 52px;
        height: 26px;
      }
    }
  }
}
