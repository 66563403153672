.menu-bar {
  min-height: 50px;
  padding-top: 10px;

  .logo {
    background: none;
    width: 140px;
    height: 65px;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 70px;

    img {
      width: 100%;
    }
  }

  a {
    text-decoration: none;
    color: $orderly-toolkit-white;
    cursor: pointer;
    font-family: 'tthoves-medium';
    margin: 0 20px;

    @media screen and (max-width: 980px) {
      margin: 0 10px;
    }

    &:hover {
      color: #a13d3e;
    }
  }

  .inner-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'tthoves-bold';
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    .header-left {
      display: flex;
      width: 50%;
      align-items: flex-start;
      margin-left: 120px;

      @media screen and (max-width: 1300px) {
        width: 70%;
      }

      @media screen and (max-width: 960px) {
        width: 100%;
      }
    }

    .training-icon {
      width: 25px;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .back-home-btn {
    position: absolute;
    cursor: pointer;
    left: 100px;
    padding: 10px;
    background-color: #fff;
    border: 0;
    margin-top: 20px;
    margin-left: 10px;
    color: black;
  }

  .back-home-btn:hover {
    color: black;
  }
}