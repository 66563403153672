.suggestion-form {
  width: 100%;
  max-width: 575px;
  background-color: $orderly-toolkit-white;
  border-radius: 5px;
  min-height: 400px;
  padding: 30px 0;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #666;
  position: relative;

  .input-wrapper {
    margin-bottom: 2rem;
  }

  .form-btn {
    background: $orderly-toolkit-black3;
    border: none;
    height: 45px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 400px;
    color: $orderly-toolkit-white;
    margin-bottom: 1.5rem;
  }

  .error {
    color: red;
    margin-top: 5px;
    padding: 5px 20px;
    // text-align: left;
  }

  .select-wrapper {
    margin-bottom: 2rem;

    label {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    select {
      background: $orderly-toolkit-grey;
      border: none;
      height: 45px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      max-width: 400px;
      border-right: 16px solid transparent;
    }
  }
}
