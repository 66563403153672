@import './../Shared/Variables/Variables.scss';

html {
  height: 100%;
}

.app {
  text-align: center;

  .app-link {
    color: #61dafb;
  }

  .body-wrapper {
    padding: 20px 0;

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: 50px;
    font-family: 'tthoves-medium';
  }

  .history-heading {
    font-size: 50px;
    font-family: 'tthoves-medium';
    margin-right: -50px;
  }
}
