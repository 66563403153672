.sub-suggestion-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  background: linear-gradient(to right, white, #f5f5f5, white);
  color: rgb(0, 0, 0);
  margin-top: 15px;
  font-size: 2rem !important;
  position: relative;

  .sub-suggestion-title {
    font-family: $orderly-font-demibold;
    padding: 10px;
  }

  .sub-suggestion-desc {
    font-size: 25px;
    padding: 25px 60px;
  }

  .progress-bar-wrapper {
    width: 50%;
    position: absolute;
    bottom: 0;
  }
}

.swipeable-list {
  &.sub-suggestion {
    margin: 15px 0;
    box-shadow: none;
    overflow-y: hidden;

    .swipe-content {
      margin-top: unset;

      .content-text {
        margin-top: 20px;
      }

      img {
        height: 40%;
      }
    }
  }
}
