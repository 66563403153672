
.content-panel {
  height: 100%;
  padding: 0 28px;
  padding-bottom: 40px;

  .video-wrapper {
    position: relative;
    width: 400px;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      object-fit: cover;
    }
  }

  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .blur {
    filter: blur(10px);
  }

  .content-panel-inner {
    margin: 0 auto;
    width: 100%;
    overflow-y: hidden;
    padding-top: 28px;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      width: 10px;
    }
  }
}
