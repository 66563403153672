.react-datepicker__input-container {
  width: 0;
  display: block;
}

.react-datepicker-wrapper,
.react-datepicker__input-container input {
  width: 115px;
}

.card-wrapper {
  margin-top: 40px;
}

.history-heading {
  color: #fff;
}

.date-wrapper {
  margin-left: 150px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  display: none;
}

.react-datepicker__input-container input {
  color: #000;
  font-weight: 500;
  border: 1px solid black;
  padding: 5px;
}

.arrow {
  border: solid #333;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10.5px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

h3 {
  color: #fff;
}
