@import './../../Shared/Variables/Variables.scss';

.textarea {
    text-align: unset;
    justify-self: flex-start;
    display: block;
    width: 100%;

    textarea {
        background: $orderly-toolkit-grey;
        border: none;
        height: 75px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        max-width: 400px;

        &::placeholder {
            font-family: "tthoves-light";
            font-size: 16px;
        }
    }
}