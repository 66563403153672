.suggestion-card {
  background-color: $orderly-toolkit-white;
  width: 60%;
  min-height: 75px;
  margin: 15px auto;
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  .bg-black {
    background-color: #6f2a3b;
    color: $orderly-toolkit-white;
    font-size: 25px;
    font-weight: 500;
    width: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
      width: 70%;
  }

  .content {
    width: 100%;
    text-align: left;
    padding: 20px 20px;
    background-color: $orderly-toolkit-white;
    color: #70757a;

    span {
      font-size: 22px;
      font-weight: 700;
      color: $orderly-toolkit-black;
    }
  }
}
