.scrollable-container {
    width: 80%; 
    max-width: 600px; 
    margin: 0 auto;
    background-color: #ffffff;
    padding: 10px; 
    border-radius: 4px; 
}

.release-number{
    color: white;
    @media only screen and (max-width: 900px) {
            margin-top: 60px;
        }
}

.release-content {
    color: black; // Set the text color
    text-align: left;
        padding: 10px 10px 0px 10px;
}

.release-title{
    font-weight: bold;
}

.view-more-button{
    position: flex;
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    border: 0;
    margin-top: 20px;
    color: black;
    justify-content: center;
    align-items: center;
}

.release-image {
    margin-top: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
}