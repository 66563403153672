.notification-container {
    margin-left: auto;
    transform: translateY(-50%);
    display: inline-block;
    position: relative;
    z-index: 1;
}

.notification-bell {
    position: relative;
    cursor: pointer;
    padding-top: 30px;
    padding-right: 30px;
}

@media screen and (max-width: 1280px) {
    .notification-bell {
        padding-left: 35px;
    }
}

.notification-dot {
    position: absolute;
    top: 25px;
    right: 27px;
    width: 10px;
    height: 10px;
    background-color: #f00000;
    border-radius: 50%;
}

.notification-panel {
    position: absolute;
    top: calc(100% + 10px);
    right: 30px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 999;
    transition: background-color 0.3s ease;
    overflow: hidden;
}

.notification-header {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    border-radius: 8px 8px 0 0;
    background-color: #f9f9f9;
    padding-right: 20px;
}

.notification-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification-close {
    cursor: pointer;
    margin-bottom: 30px;
}

.notification-content {
    padding: 10px;
    overflow-y: auto;
    max-height: 350px;
    width: 430px;
}

.notification-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    height: 90px;
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
}

.notification-details {
    flex-grow: 1;
    margin-right: 10px;
    cursor: pointer;
    white-space: break-spaces;
}

.notification-dot-unread,
.notification-dot-read {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
    cursor: pointer;
}

.notification-dot-unread {
    font-size: 10px;
}

.notification-dot-read {
    visibility: hidden;
    cursor: default;
}

.notification-title {
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.notification-description {
    margin-bottom: 5px;
    font-size: 0.8rem;
    font-family: "tthoves-regular";
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.4rem;
}

.notification-date {
    font-size: 0.6rem;
    color: #888;
    margin-top: 20px;
}

.notification-footer {
    padding: 10px;
    border-top: 1px solid #ccc;
    border-radius: 0 0 8px 8px;
}

.mark-all-as-read {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    color: #3e98c7;
    font-size: 0.9rem;
}

.mark-icon {
    margin-right: 5px;
}

.notification-item.unread {
    background-color: #eee;
}

.notification-item:hover {
    background-color: #f2f2f2;
    transition: background-color 0.2s ease;
}

.notification-pointer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.notification-pointer {
    transform: rotate(35deg);
    cursor: pointer;
    margin-bottom: 5px;
}

.notification-tap-here {
    font-size: 12px;
    font-family: 'tthoves-regular';
    color: gray;
    width: 50px;
}

.notification-header-title {
    font-size: 1rem;
    margin-bottom: 10px;
    margin-left: 10px;
}