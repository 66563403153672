.swipe-content {
  background-color: #fff;
  color: #292827;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2rem !important;
  font-weight: 1000;
  padding-top: 70px;
  margin-top: 70px;

  .content-text {
    visibility: hidden;
    opacity: 0;
    margin-top: 50px;
    padding: 0 30px;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  img {
    height: 35%;
    object-fit: contain;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
