@import './../../Shared/Variables/Variables.scss';

.input {
    text-align: unset;
    justify-self: flex-start;
    display: block;
    width: 100%;

    input {
        background: $orderly-toolkit-grey;
        border: none;
        height: 45px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        max-width: 400px;
    
    }
}