.release-summary {
    background-color: #fff;
    color: #000;
    display: flex;
    width: 60%;
    margin: 0 auto 20px auto;
    cursor: pointer;

    @media only screen and (max-width: 1190px) {
            width: 90%;
        }
        
    height: 165px;    
}

.release-summary-title {
    font-weight: bold;
    margin-bottom: 8px;
}

.release-img-container {
    flex: 0 0 auto;
    background-color: white;
}

.release-summary-img {
    height: 100%;
    width: 100%;
    object-fit: fill;
    cursor: pointer;
}

.release-summary-text {
    flex: 1;
    background-color: white;
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }

.release-summary-datetime {
    font-weight: bold;
    margin-right: 5px;
    align-items: flex-end;
    margin-top: auto;
}

.release-summary-date {
    float: right;
}

.release-summary-desc {
    margin-top: 10px;
    margin-bottom: auto;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.release-catalog-title {
    color: white;

    @media only screen and (max-width: 900px) {
        margin-top: 60px;
    }
}