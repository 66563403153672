.card-item {
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  font-weight: bolder;
  text-align: left;
  padding: 0 25px;
  min-height: 195px;
  max-height: 195px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-family: 'tthoves-bold';
      color: #7a7979;
    }

    .percentage {
      font-size: 22px;
      font-family: 'tthoves-bold';
      color: #d10404;
      display: flex;
    }
  }

  .content {
    font-size: calc(2em + 1vmin);
    font-weight: bolder !important;
    width: 100%;
    max-height: 40px;
    font-family: 'tthoves-bold';

    &.text {
      font-size: calc(1.2em + 1vmin);

      @media screen and (max-width: 1140px) {
        font-size: calc(1.1em + 0.1vmin);
      }
    }
  }

  .good {
    color: green;
  }

  .bad {
    color: red;
  }
}

.up-arrow {
  transform: rotate(180deg);
}
